import React from 'react';

function Table(props) {
    let classNames = props.classNames ? props.classNames : {}
    return <table className={classNames["table"]}>
        <thead className={classNames["thead"]}>
            <tr className={classNames["thead.tr"]}>
                {props.headers.map(header =>
                    <th className={classNames["th." + header]} key={header}>
                        {props.translation.get(header)}
                    </th>
                )}
            </tr>
        </thead>
        <tbody className={classNames["tbody"]}>
            {props.data.map((dataElem, index) =>
                <tr className={classNames["tbody.tr"]} key={dataElem.key ? dataElem.key : index}>
                    {props.headers.map(header =>
                        <td className={classNames["td."+header]} key={header}>
                            {dataElem[header]}
                        </td>
                    )}
                </tr>
            )}
        </tbody>
    </table>
}

export default Table;