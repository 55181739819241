import React from 'react';
import './App.css'
import './Data.css'
import { updatedState } from "./FetchInfo";
import { Inputs, url2inputState } from './Inputs'
import { initTranslation, TranslationSelect } from './Translation';
import { Korba, korbaSource } from './Korba';
import { SXVII, sXVIIsource } from './SVXII';
import { CBDU, cbduSource } from "./CBDU";
import { Kartoteka, kartotekaSource } from "./Kartoteka";
import { Footer } from "./Footer";
import Wide from "./Wide";
import gitInfo from "../gitInfo";

const korba = korbaSource()
const sXVII = sXVIIsource()
const cbdu = cbduSource()
const kartoteka = kartotekaSource()

class App extends React.Component {
    constructor(props) {
        super(props)

        this.handleInputState = this.handleInputState.bind(this)
        this.handleSourceData = this.handleSourceData.bind(this)
        this.handleTranslation = this.handleTranslation.bind(this)
        this.handleResize = this.handleResize.bind(this)

        let allSources = {}
        allSources[korba.key()] = korba
        allSources[sXVII.key()] = sXVII
        allSources[cbdu.key()] = cbdu
        allSources[kartoteka.key()] = kartoteka
        const allSearchTypes = ['orth', 'base']
        this.state = this.initState(allSources, allSearchTypes, initTranslation())
    }

    initState(allSources, allSearchTypes, translation) {
        return {
            inputState: url2inputState(new URLSearchParams(window.location.search), allSources),
            translation: translation,
            allSources,
            allSearchTypes,
            sourceData: {},
            wide: new Wide(window.innerWidth, false, null, translation.code()),
            firstSearch: false
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    hasSomeData(oldState) {
        return oldState.sourceData && Object.keys(oldState.sourceData).length !== 0
    }

    handleResize() {
        this.setState(oldState => updatedState(oldState, "wide",
            new Wide(window.innerWidth, this.hasSomeData(oldState), null, oldState.translation.code())))
    }

    handleTranslation(translation) {
        this.setState(oldState => updatedState(oldState, "translation", translation))
        this.setState(oldState => updatedState(oldState, "wide",
            new Wide(window.innerWidth, this.hasSomeData(oldState), null, translation.code())))
        document.title = translation.get("app.title")
    }

    searchingState(oldState, inputState) {
        if (!inputState.query || inputState.query === "") return oldState
        let newState = updatedState(oldState, "inputState", inputState)
        newState = updatedState(newState, "sourceData", {})
        newState = updatedState(newState, "wide",
            new Wide(window.innerWidth, true, null, oldState.translation.code()))
        return newState;
    }

    doSearch(inputState) {
        if (!this.state.firstSearch)
            this.setState(oldState => updatedState(oldState, "firstSearch", true))
        if (!inputState.query || inputState.query === "") return
        for (const [key, source] of Object.entries(this.state.allSources)) {
            if (inputState.sources[key]) {
                let inputStateCopy = { ...inputState }
                let stateLogic = {
                    input: () => inputStateCopy,
                    setState: mapping => this.handleSourceData(oldSourceData => updatedState(oldSourceData, key, mapping))
                }
                source.fetchData(stateLogic)
            }
        }
    }

    handleInputState(inputState) {
        this.setState(oldState => this.searchingState(oldState, inputState))
        this.doSearch(inputState)
    }

    handleSourceData(mapping) {
        this.setState(oldState => updatedState(oldState, "sourceData", mapping))
    }

    render() {
        const wide = this.state.wide

        const dataDiv = wide.isEmpty() ? <></> : <div className={wide.get("Data")}>
            <h2 className={wide.get("Data-Header")}>{this.state.translation.get("data.header")}</h2>
            <Conditional translation={this.state.translation} sourceData={this.state.sourceData} wide={wide}
                         fun={Korba} source={korba} />
            <Conditional translation={this.state.translation} sourceData={this.state.sourceData} wide={wide}
                         fun={SXVII} source={sXVII} />
            <Conditional translation={this.state.translation} sourceData={this.state.sourceData} wide={wide}
                         fun={CBDU} source={cbdu} />
            <Conditional translation={this.state.translation} sourceData={this.state.sourceData} wide={wide}
                         fun={Kartoteka} source={kartoteka} />
            <div className={wide.get("Data-Bottom")}> </div>
        </div>

        return <>
            <div className={wide.get("App")}>
                <div id="gitInfo" style={{display: "none"}}>{gitInfo.commit} {gitInfo.date}</div>

                <AppTitle wide={wide} translation={this.state.translation} />
                <TranslationSelect handleTranslation={this.handleTranslation} translation={this.state.translation} wide={wide}/>
                <Inputs translation={this.state.translation}
                        wide={wide}
                        allSources={this.state.allSources}
                        allSearchTypes={this.state.allSearchTypes}
                        inputState={this.state.inputState}
                        handleInputState={this.handleInputState}
                        firstSearch={this.state.firstSearch}/>
                <div className={wide.get("App-Bottom")}> </div>
            </div>
            {dataDiv}
            <Footer wide={wide}/>
        </>
  }
}

function AppTitle(props) {
    return <div className={props.wide.get("App-Title")}>
        <div className={props.wide.get("App-Title-Main")}>{props.translation.get("app.title.main")}</div>
        <div className={props.wide.get("App-Title-Sub")}>{props.translation.get("app.title.sub")}</div>
    </div>
}

function Conditional(props) {
    if (!props.sourceData || !props.sourceData[props.source.key()])
            return <></>
    return <>
        <div className={props.wide.get("Data-SourceHeader")}>{props.translation.get(props.source.key())}</div>
        {props.fun({...props.sourceData[props.source.key()], translation: props.translation, wide: props.wide})}
    </>
}

export default App;