import React from 'react';

class FetchState {
    constructor(url, ready, error, result) {
        this.url = url
        this.ready = ready
        this.error = error
        this.result = result
    }

    static empty(url) { return new FetchState(url, false, null, null); }
    static error(url, err) { return new FetchState(url, true, err, null); }
    static ok(url, res) { return new FetchState(url, true, null, res); }
}

function checkBreakOptions(breakOptions) {
    const retOptions = breakOptions ? {...breakOptions} : {}
    if (!retOptions.timeout)
        retOptions.timeout = 30000 //ms
    if (!retOptions.maxSize)
        retOptions.maxSize = 500000 //bytes
    return retOptions
}

function tooBig(res, breakOptions) {
    if (!res.headers.get('Content-Length')) return res
    const contentLength = res.headers.get('Content-Length')
    if (contentLength > breakOptions.maxSize)
        throw new Error("Response too large to parse")
    return res
}

function tooBigText(text, breakOptions) {
    if (text && text.length > breakOptions.maxSize)
        throw new Error("Response too large to parse")
    return text
}

function fetchAndParse(url, options, breakOptions) {
    const controller = new AbortController()
    breakOptions = checkBreakOptions(breakOptions)
    const timeoutId = setTimeout(() => controller.abort(), breakOptions.timeout)
    if (!options) options = {}
    if (!options.signal) {
        options.signal = controller.signal
    }
    return window.fetch(url, options)
        .then(res => { if (!res.ok) throw new Error(res.statusText); else return res; })
        .then(res => tooBig(res, breakOptions))
        .then(res => res.text())
        .then(text => tooBigText(text, breakOptions))
        .then(text => JSON.parse(text))
        .then(response => {
                console.log("Fetching response", url, response)
                return FetchState.ok(url, response)
            })
        .catch(error => {
                console.log("Fetching error", url, error)
                return FetchState.error(url, error)
            });
}

function FetchInfo(props) {
    if (!props.ready)
        return <div>{props.translation.get("loading")} {props.url}</div>
    if (props.error)
        return <div><strong>{props.error.message}</strong></div>
    return <></>;
}

function updatedStateRec(oldState, fields, pos, mapping) {
    if (pos >= fields.length) return mapping(oldState)

    let idx = fields[pos]
    let newState = !oldState ? (Number.isInteger(idx) ? [] : {})
                             : (Array.isArray(oldState) ? [...oldState] : {...oldState})

    newState[idx] = updatedStateRec(oldState ? oldState[idx] : oldState, fields, pos + 1, mapping)
    return newState
}

function updatedState(oldState, fields, valueMapping) {
    if (typeof fields === 'string' || fields instanceof String)
        fields = fields.split(".")
    else if (Number.isInteger(fields))
        fields = [fields]

    let mapping = (typeof valueMapping === 'function' || valueMapping instanceof Function) ?
        valueMapping :
        unused => valueMapping

    return updatedStateRec(oldState, fields, 0, mapping)
}

export { FetchInfo, FetchState, fetchAndParse, updatedState }