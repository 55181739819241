import React from 'react';
import { updatedState } from "./FetchInfo";
import './Inputs.css'

function initInputState(allSources) {
    return {
        query: "",
        searchType: 'base',
        sources: allValidSources(allSources, 'base'), // { sourceKey: boolean - if it should be used }
    }
}

function validSources(allSources, searchType, sources) {
    let resultSources = {}
    for (const [key, source] of Object.entries(allSources))
        resultSources[key] = sources[key] && source.canSearch(searchType)
    return resultSources
}

function allValidSources(allSources, searchType) {
    return validSources(allSources, searchType, allSources)
}

function validState(allSources, allSearchTypes, inputState, translation) {
    const defaultState = initInputState(allSources)
    if (!inputState)
        return defaultState;
    let resultState = {
        query: inputState.query ? inputState.query : defaultState.query,
        searchType: allSearchTypes.includes(inputState.searchType) ? inputState.searchType : defaultState.searchType,
    }
    let resultSources = validSources(allSources, resultState.searchType, inputState.sources)
    resultState.sources = (Object.values(resultSources).filter(x => x).length === 0) ?
        allValidSources(allSources, resultState.searchType) : resultSources
    if (resultState.query && resultState.query !== "")
        resultState.queryLink = inputState2url(resultState, translation)

    return resultState
}

const queryParamName = "q"
const searchTypeParamName = "t"
const sourceParamName = "s"

function url2inputState(urlSearchParams, allSources) {
    const query = urlSearchParams.get(queryParamName)
    const searchType = urlSearchParams.get(searchTypeParamName)
    const sources = {}
    urlSearchParams.getAll(sourceParamName).filter(s => allSources[s]).forEach(s => { sources[s] = true })
    return {
        query: query,
        searchType: searchType,
        sources: sources,
    }
}

function inputState2url(inputState, translation) {
    let sources = Object.entries(inputState.sources)
        .filter(pair => pair[1])
        .map(pair => sourceParamName + "=" + pair[0]).join("&")
    let s = sources === "" ? "" : ("&" + sources)
    let t = translation.code() === "pl" ? "" : "&t=" + translation.code()
    return queryParamName + "=" + encodeURIComponent(inputState.query) + "&" +
        searchTypeParamName + "=" + inputState.searchType +
        s + t
}

class Inputs extends React.Component {
    constructor(props) {
        super(props);
        this.state = validState(props.allSources, props.allSearchTypes, props.inputState, props.translation)

        this.updateState = this.updateState.bind(this);
        this.updateSourceSelection = this.updateSourceSelection.bind(this);
        this.updateSearchType = this.updateSearchType.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputState = props.handleInputState
        this.handleSearch = props.handleSearch

        if (this.state.query && this.state.query !== "" && !this.props.firstSearch)
            this.performSearch();
    }

    updateState(field, value) {
        this.setState(oldState => updatedState(oldState, field, value))
    }

    updateSourceSelection(source, value) {
        this.setState(oldState => updatedState(oldState, ["sources", source], value))
    }

    updateSearchType(searchType) {
        this.updateState("searchType", searchType);
        this.updateState("sources", allValidSources(this.props.allSources, searchType));
    }

    prepareResult() {
        return { ...this.state }
    }

    handleSubmit(event) {
        event.preventDefault()
        this.performSearch()
    }

    performSearch() {
        let inputState = this.prepareResult()
        if (!inputState.query || inputState.query === "") return
        let newQueryLink = inputState2url(inputState, this.props.translation)
        this.updateState("queryLink", newQueryLink)
        inputState.queryLink = newQueryLink

        this.handleInputState(inputState)
    }

    render() {
        const wide = this.props.wide
        return <div className={wide.get("Inputs")}>
            <form onSubmit={this.handleSubmit}>
                <div className={wide.get("Inputs-Query-Div")}>
                    <label htmlFor="inputs.query" className={wide.get("Input-Label")}>
                        {this.props.translation.get("inputs.query")}
                    </label>
                    <input name={queryParamName} id="inputs.query" className={wide.get("Inputs-Query")}
                           onChange={event => this.updateState("query", event.target.value)}
                           value={this.state.query}></input>
                </div>
                <div className={wide.get("Inputs-SearchType-Div")}>
                    <label htmlFor="inputs.searchType" className={wide.get("Inputs-Label")}>
                        {this.props.translation.get("inputs.searchType")}
                    </label>
                    <select name={searchTypeParamName} id="inputs.searchType" className={wide.get("Inputs-SearchType")}
                            onChange={event => this.updateSearchType(event.target.value)}
                            value={this.state.searchType}>
                        {
                            this.props.allSearchTypes.map(searchType =>
                                <option key={searchType} value={searchType}>
                                    {this.props.translation.get("inputs." + searchType)}
                                </option>)
                        }
                    </select>
                </div>

                <input type="submit" value={this.props.translation.get("inputs.submit")}
                       className={wide.get("Inputs-Submit")}/>

                <div>
                    <QueryLink translation={this.props.translation} inputState={this.state} wide={wide} />
                </div>

                <fieldset id="inputs.sources" className={wide.get("Inputs-Sources")}>
                    <label className={wide.get("Inputs-Label")}>{this.props.translation.get("inputs.sources")}</label>
                    {Object.entries(this.props.allSources).map(entry => {
                        const id = entry[0];
                        const source = entry[1];
                        const allowed = source.canSearch(this.state.searchType);
                        const checked = !! (allowed && this.state.sources[id]);
                        return (<div key={id}>
                            <input type="checkbox" name={sourceParamName} id={id} value={id}
                                   disabled={!allowed}
                                   checked={checked}
                                   onChange={event => this.updateSourceSelection(id, event.target.checked)} />
                            <label htmlFor={id}>{this.props.translation.get(id)}</label>
                        </div>)})
                    }
                </fieldset>
            </form>
        </div>
    }
}


function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    let successful = true
    try {
        successful = document.execCommand('copy');
    } catch (err) {
        successful = false
    }

    document.body.removeChild(textArea);
    return successful
}

function copyTextToClipboard(text, resultFunc) {
    if (!navigator.clipboard) {
        let successful = fallbackCopyTextToClipboard(text);
        resultFunc(successful)
    }
    navigator.clipboard.writeText(text).then(function() {
        resultFunc(true)
    }, function(err) {
        resultFunc(false)
    });
}

class QueryLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = { successfulCopy: true }
        this.buttonClick = this.buttonClick.bind(this)
        this.wasCopied = this.wasCopied.bind(this)
    }

    wasCopied(result) {
        this.setState(oldState => updatedState(oldState, "successfulCopy", result))
    }

    buttonClick(event) {
        let directLink = document.baseURI + this.queryLink()
        copyTextToClipboard(directLink, this.wasCopied)
        if (this.state.successfulCopy)
            event.preventDefault()
    }

    queryLink() {
        let translationParam = this.props.translation.queryParam()
        if (translationParam !== "") translationParam = translationParam + "&"
        return "?" + translationParam + this.props.inputState.queryLink
    }

    render() {
        if (!this.props.inputState || !this.props.inputState.queryLink || this.props.inputState.queryLink === "")
            return <></>

        return <a href={this.queryLink()} className={this.props.wide.get("Inputs-QueryLink")} onClick={event => this.buttonClick(event)}>
            {this.props.translation.get("inputs.queryLink")}
        </a>
    }
}

export { Inputs, url2inputState };