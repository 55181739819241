class Wide {
    constructor(width, hasData, widePref, lang) {
        this.lang = lang
        this.some = hasData ? "Some" : "Empty"
        if (widePref !== null) {
            this.wide = widePref
        } else {
            // this.wide = width > 100 ? "Wide" : "Narrow" // 1080
            this.wide = "Wide"
        }
        this.classes = {
            "App": {w: true, s: true },
            "App-Title-Main": { l: true },
            "App-Title-Sub": {},
            "Inputs": {w: true},
            "Footer": {},
            "Footer-Center": {w: true},
            "Footer-info": {},
            "Footer-text": {},
            "Footer-small-text": {},
            "Footer-half": {},
            "Data": { w: true },
            "Data-Number": {},
            "Data-String": {},
            "Data-At": {},
            "Data-Hash": {},
            "Data-R": {},
            "Translation": { w: true }
        }
    }

    isWide() { return this.wide === "Wide" }
    isEmpty() { return this.some === "Empty" }

    get(className) {
        let info = this.classes[className]
        let checkClass = className
        while (!info && checkClass.length > 0) {
            const lastDash = checkClass.lastIndexOf("-")
            if (lastDash === -1) break;
            checkClass = checkClass.substring(0, lastDash)
            info = this.classes[checkClass]
        }

        if (!info) return className
        const w = info.w ? ("-" + this.wide) : ""
        const s = info.s ? ("-" + this.some) : ""
        const l = info.l ? ("-" + this.lang) : ""
        return className + w + s + l
    }
}


export default Wide