import './Footer.css'

const IJP_LINK = "https://ijp.pan.pl/"

function Footer(props) {
    let wide = props.wide;
    return <div className={wide.get("Footer")}>
        <div className={wide.get("Footer-Center")}>
            <div className={wide.get("Footer-half")}>
                <div className={wide.get("Footer-info")}>
                    <a href={IJP_LINK} target="_blank"><img src="logo_ijp.png"/></a>
                </div>
                <div className={wide.get("Footer-text")}>
                    Copyright © <br/> Instytut Języka Polskiego PAN
                </div>
            </div>
            <div className="Footer-half">
                <div className="Footer-info">
                    <img src="nprh_logo_mono_white.png"/>
                </div>
                <div className={wide.get("Footer-small-text")}>
                    Praca naukowa finansowana w ramach programu Ministra
                    Nauki i Szkolnictwa Wyższego pod nazwą
                    „Narodowy Program Rozwoju Humanistyki” w latach 2019-2023
                </div>
            </div>
        </div>
    </div>
}

export { Footer }