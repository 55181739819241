import React from 'react';
import './Translation.css'

const translationCodeParam = "lang"

const TranslationPlData = {
  "inputs.base": "Lemat",
  "inputs.orth": "Forma",
  "inputs.query": "Zapytanie",
  "inputs.searchType": "Typ jednostki",
  "inputs.sources": "Źródła do przeszukania",
  "inputs.submit": "Szukaj",
  "inputs.queryLink": "Kopiuj link do bieżącego wyszukiwania",
  "korba": "Elektroniczny Korpus Tekstów Polskich z XVII i XVIII w. (KorBa)",
  "korba.allResults": "Wystąpienia",
  "korba.documentKey": "Skrót tekstu",
  "korba.forms.header": "Wyniki z podziałem na części mowy i formy gramatyczne",
  "korba.hit": "Rezultat",
  "korba.leftCtx": "Lewy kontekst",
  "korba.link": "Zobacz wyniki w KorBie",
  "korba.quotes.header": "Przykładowe wyniki z KorBy",
  "korba.rightCtx": "Prawy kontekst",
  "sXVII": "Elektroniczny Słownik Języka Polskiego XVII i XVIII wieku",
  "sXVII.haslo.czm": "Część mowy",
  "sXVII.haslo.additional": "Informacje dodatkowe",
  "sXVII.haslo.formy": "Inne formy",
  "sXVII.link": "Zobacz w słowniku",
  "sXVII.stan_opracowania.zalążek": "zalążek",
  "sXVII.stan_opracowania.w opracowaniu": "w opracowaniu",
  "sXVII.stan_opracowania.opracowany": "",
  "sXVII.parentLink": "Link do hasła głównego",
  "sXVII.sub": "Podhasło",
  "sXVII.znaczenia": "Znaczenia",
  "sXVII.znaczenia.noResults": "Hasło w opracowaniu - nie podano jeszcze definicji",
  "sXVII.im.przym.warn": "Imiesłowy przymiotnikowe są w Korbie lematyzowane do bezokoliczników",
  "cbdu": "Cyfrowa Biblioteka Druków Ulotnych Polskich i Polski Dotyczących z XVI, XVII i XVIII Wieku (CBDU)",
  "cbdu.year": "Rok",
  "cbdu.title": "Tytuł",
  "kartoteka": "Kartoteka Słownika języka polskiego XVII i 1. połowy XVIII wieku",
  "kartoteka.link": "Zobacz w kartotece",
  "loading": "Trwa ściąganie danych",
  "noResults": "Brak wyników",
  "orth": "Forma",
  "tag": "Znacznik morfosyntaktyczny",
  "frequency": "Wystąpienia",
  "link": "Link",
  "expand": "Rozwiń",
  "collapse": "Zwiń",
  "": "",
  "app.title": "Polszczyzna XVII i XVIII wieku",
  "app.title.main": "Polszczyzna XVII i XVIII wieku",
  "app.title.sub": "Słowniki, korpusy, źródła",
  "data.header": "WYNIKI WYSZUKIWANIA",
  "translation.code": "pl",
  "manual.link": "Instrukcja_korzystania_z_serwisu_Polszczyzna_XVII_i_XVIII_w.pdf",
  "manual.desc": "Instrukcja"
}

const TranslationEnData = {
  "inputs.base": "Lemma",
  "inputs.orth": "Form",
  "inputs.query": "Query",
  "inputs.searchType": "Type of the searched unit",
  "inputs.sources": "Sources to search",
  "inputs.submit": "Search",
  "inputs.queryLink": "Copy current search link",
  "korba": <>Corpus of 17<sup>th</sup>- and 18<sup>th</sup>-century Polish Texts (KorBa)</>,
  "korba.allResults": "Results",
  "korba.documentKey": "Text ID",
  "korba.forms.header": "Results divided into parts of speech and grammatical forms",
  "korba.hit": "Result",
  "korba.leftCtx": "Left context",
  "korba.link": "Results from KorBa",
  "korba.quotes.header": "Corpus result examples",
  "korba.rightCtx": "Right context",
  "sXVII": <>Electronic Dictionary of the 17<sup>th</sup>- and 18<sup>th</sup>-century Polish</>,
  "sXVII.haslo.czm": "Part of speech",
  "sXVII.haslo.additional": "Additional information",
  "sXVII.haslo.formy": "Other forms",
  "sXVII.link": "Link to the dictionary",
  "sXVII.stan_opracowania.zalążek": "Stub",
  "sXVII.stan_opracowania.w opracowaniu": "In preparation",
  "sXVII.stan_opracowania.opracowany": "",
  "sXVII.parentLink": "Link to the parent entry",
  "sXVII.sub": "Sub entry",
  "sXVII.znaczenia": "Meanings",
  "sXVII.znaczenia.noResults": "Entry in preparation - no definitions yet",
  "sXVII.im.przym.warn": "Adjectival participles in Korba are lemmatized to infinitives",
  "cbdu": "Digital Library of Polish and Poland-Related News Pamphlets from the 16th to the 18th Century (CBDU)",
  "cbdu.year": "Year",
  "cbdu.title": "Title",
  "kartoteka": <>Card index of the Dictionary of the Polish Language of the 17<sup>th</sup>
    and First Half of the 18<sup>th</sup> Century</>,
  "kartoteka.link": "Link to card index",
  "loading": "Downloading data",
  "noResults": "No results found",
  "orth": "Form",
  "tag": "Morphosyntactic tag",
  "frequency": "Frequency",
  "link": "Link",
  "expand": "Expand",
  "collapse": "Collapse",
  "": "",
  "app.title": "Polish language of the 17th and 18th centuries",
  "app.title.main": <>Polish language of the 17<sup>th</sup> and 18<sup>th</sup> centuries</>,
  "app.title.sub": "Dictionaries, corpora, resources",
  "data.header": "SEARCH RESULTS",
  "translation.code": "en",
  "manual.link": "Polish_Language_of_the_17th_and_18th_Centuries_Website_User_Manual.pdf",
  "manual.desc": "User manual"
}

class Translation {
  constructor(translation) {
    this.translation = translation;
  }

  get(key) {
    const value = this.translation[key]
    return value ? value : ("missing translation: " + key)
  }
  getOrKey(key, prefix) {
    const searchKey = prefix ? (prefix + "." + key) : key
    const value = this.translation[searchKey]
    return value ? value : key
  }

  queryParam() {
    if (this.get("translation.code") === defaultTranslation.get("translation.code"))
      return ""
    return translationCodeParam + "=" + this.get("translation.code")
  }

  code() { return this.get("translation.code") }
}

const TranslationPl = new Translation(TranslationPlData)
const TranslationEn = new Translation(TranslationEnData)
const defaultTranslation = TranslationPl

function initTranslation() {
  let translationCode = new URLSearchParams(window.location.search).get(translationCodeParam)
  if (TranslationEn.get("translation.code") === translationCode) return TranslationEn
  if (TranslationPl.get("translation.code") === translationCode) return TranslationPl
  return defaultTranslation
}

function TranslationSelect(props) {
  const wide = props.wide
  return <div className={wide.get("Translation")}>
      <div className={wide.get("Translation-Flag")}>
        <a className={wide.get("Translation-Manual")}
           href={props.translation.get("manual.link")}
           target="_blank">
          {props.translation.get("manual.desc")}
        </a>
      </div>
      <div className={wide.get("Translation-Flag")}>
        <img alt="Wersja polska"
             title="Wersja polska"
             src="pl.png"
             onClick={event => props.handleTranslation(TranslationPl)} />
      </div>
      <div className={wide.get("Translation-Flag")}>
        <img alt="English version"
            title="English version"
            src="en.png"
            onClick={event => props.handleTranslation(TranslationEn)} />
      </div>
  </div>
}

export { initTranslation, TranslationSelect };