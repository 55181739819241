import React from 'react'
import {FetchInfo, FetchState, fetchAndParse, updatedState} from "./FetchInfo"
import Source from "./Source"
import "./Data.css"

const sXVIIAdress = "https://xvii-wiek.ijp.pan.pl/"
const sXVIILinkAdress = "https://sxvii.pl/"

function searchUrl(base) {
    return sXVIIAdress + "ajax/json.php?elementy=haslo,znaczenia,formy,cytaty,podhasla&haslo=^" +
        encodeURIComponent(base) +"$"
}

function sXVIIlink(id_hasla) {
    return sXVIILinkAdress + "index.php?strona=haslo&id_hasla=" + id_hasla
}
function fetchSXVIIdata(stateLogic) {
    let inputState = stateLogic.input()
    let fetchUrl = searchUrl(inputState.query)
    stateLogic.setState({
        baseFetch: FetchState.empty(fetchUrl)
    })

    fetchAndParse(fetchUrl)
        .then(fetchState => stateLogic.setState(oldState => updatedState(oldState, "baseFetch", fetchState)))
}

function sXVIIsource() {
    return new Source('sXVII', fetchSXVIIdata, null)
}

function SXVII(props) {
    const wide = props.wide
    if (!props.baseFetch.result)
        return <FetchInfo {...props.baseFetch} translation={props.translation} />
    if (props.baseFetch.result.length === 0)
        return <div className={wide.get("Data-Div")}>{props.translation.get("noResults")}</div>
    let elemsToShow = orderElems(props.baseFetch.result)
    return <>
            {elemsToShow.map(elem =>
                <div className={wide.get("Data-Div")} key={elem.haslo.id_hasla}>
                    <SXVIIElem elem={elem} translation={props.translation} wide={wide}/>
                </div>)}
            <SXVIIImPrzymWarn wide={wide} translation={props.translation} result={props.baseFetch.result} />
    </>;
}

function SXVIIElem(props) {
    return <>
        <SXVIIElemHasloHeader {...props} />
        <SXVIIElemHasloSub {...props} />
        <SXVIIElemHasloFormy {...props} />
        <SXVIIElemCzMowy {...props} />
        <SXVIIElemZnaczenia {...props} />
        <SXVIIElemHasloAdditional {...props} />
    </>;
}

function orderElems(elems) {
    let nadHasla = {}
    elems.filter(elem => !elem.id_nadhasla)
        .forEach(elem => nadHasla[elem.haslo.id_hasla] = elem)

    let toShow = elems.filter(elem => !!elem)
    let comparator = compareElems(nadHasla)
    toShow.sort(comparator)

    return toShow
}

function formForOrder(nadHasla, elem) {
    if (!elem.id_nadhasla) return "a" + headForm(elem) + "a"
    if (!nadHasla[elem.id_nadhasla]) return "b" + headForm(elem)
    return "a" + headForm(nadHasla[elem.id_nadhasla]) + "b" + headForm(elem)
}

function compareElems(nadHasla) {
    return function (a, b) {
        let formA = formForOrder(nadHasla, a)
        let formB = formForOrder(nadHasla, b)
        let cmpFormOrth = formA.localeCompare(formB, "pl")
        if (cmpFormOrth !== 0) return cmpFormOrth

        if (a.haslo.id_hasla < b.haslo.id_hasla) return -1
        if (a.haslo.id_hasla > b.haslo.id_hasla) return 1
        return 0
    }
}

function formOrth(form) {
    let orth = form.forma
    if (form.homonimia)
        orth += ' ' + form.homonimia
    return orth
}

function headForm(elem) {
    let formyHaslowe = elem.formy.filter(form => form.typ === "forma hasłowa")
    if (formyHaslowe.length > 0) return formOrth(formyHaslowe[0])

    return formOrth(elem.formy[0])
}

function SXVIIElemHasloHeader(props) {
    const wide = props.wide
    return <div className={wide.get("Data-Line")}>
        <span className={wide.get("Data-SubHeader")}>{headForm(props.elem)}</span>
        <SXVIIElemHasloStanOpracowania {...props} />
        {' '}
        <a href={sXVIIlink(props.elem.haslo.id_hasla)} target="_blank">{props.translation.get("sXVII.link")}</a>
    </div>
}

function SXVIIElemHasloStanOpracowania(props) {
    const state = props.elem.haslo.stan_opracowania
    if (!state) return <></>
    const stateDesc = props.translation.getOrKey(state, "sXVII.stan_opracowania")
    if (stateDesc === "") return <></>
    return <>{' '}({stateDesc})</>
}

function SXVIIElemHasloSub(props) {
    const wide = props.wide
    const parent_id = props.elem.id_nadhasla
    return parent_id ?
        <div className={wide.get("Data-Line")}>
            <span className={wide.get("Data-Tag")}>{props.translation.get("sXVII.sub")}: </span>
            <a href={sXVIIlink(parent_id)} target="_blank">{props.translation.get("sXVII.parentLink")}</a>
        </div> :
        <></>
}

function SXVIIElemHasloAdditional(props) {
    const additional = props.elem.haslo.informacje_dodatkowe
    const wide = props.wide
    return additional ?
        <div className={wide.get("Data-Line")}>
            <div className={wide.get("Data-Tag")}>{props.translation.get("sXVII.haslo.additional")}: </div>
            <div>{additional}</div>
        </div> : <></>
}

function SXVIIElemHasloFormy(props) {
    const wide = props.wide
    let orths = props.elem.formy ? props.elem.formy : []
    if (orths.length <= 1) return <></>
    let headF = headForm(props.elem)
    return <div className={wide.get("Data-Line")}>
        <span className={wide.get("Data-Tag")}>{props.translation.get("sXVII.haslo.formy")}: </span>
        {' '}
        {orths.map(formOrth).filter(f => f !== headF).join(", ")}
    </div>
}

function SXVIIElemCzMowy(props) {
    if (!props.elem.haslo.czm) return <></>
    const wide = props.wide
    return <div className={wide.get("Data-Line")}>
        <span className={wide.get("Data-Tag")}>{props.translation.get("sXVII.haslo.czm")}: </span> {props.elem.haslo.czm}
    </div>
}

function buildCytatyMap(cytaty) {
    const map = {}
    if (cytaty)
        for (const cytat of cytaty)
            if (cytat.typ === "znaczenia" && cytat.id_znaczenia)
                map[cytat.id_znaczenia] = cytat.cytat
    return map
}

function SXVIIEmptyZnaczenia(props) {
    let wide = props.wide
    let cytat = (props.elem.cytaty && props.elem.cytaty.length > 0) ? props.elem.cytaty[0] : null

    return <div className={wide.get("Data-Line")}>
        <div className={wide.get("Data-Tag")}>{props.translation.get("sXVII.znaczenia.noResults")}</div>
        {
            cytat ? <SXVIIElemZnaczeniaCytat cytat={cytat.cytat} wide={props.wide} /> : <></>
        }
    </div>
}

function SXVIIElemZnaczenie(props) {
    return <>
        <SXVIIElemZnaczeniaDef definicja={props.znaczenie.definicja} wide={props.wide} />
        <SXVIIElemZnaczeniaCytat cytat={props.cytaty[props.znaczenie.id_znaczenia]} wide={props.wide} />
    </>
}

function SXVIIElemZnaczeniaDef(props) {
    return props.definicja ?
        <div>»{props.definicja}«</div> :
        <></>
}

function SXVIIElemZnaczeniaCytat(props) {
    if (!props.cytat) return <></>
    let wide = props.wide
    let strArr = ["" + props.cytat]

    let noHash = markSpan(strArr, "#", wide.get("Data-Hash"))
    let noR = markSpan(noHash, "®", wide.get("Data-R"))
    let noAt = markSpan(noR, "@", wide.get("Data-At"))
    return <div className={wide.get("Data-Quote")}>{noAt}</div>
}

function markSpan(strArr, mark, spanClass) {
    let on = false
    let out = []
    for (let ai = 0; ai < strArr.length; ai++) {
        let str = strArr[ai]
        if (typeof str === 'string' || str instanceof String) {
            let group = []
            for (let i = 0; i < str.length; i++) {
                let letter = str.charAt(i)
                if (letter === mark) {
                    on = !on
                    group = group.join("")
                    out[out.length] = on ? group : <span className={spanClass} key={"" + ai + "-" + i}>{group}</span>
                    group = []
                } else {
                    group[group.length] = letter
                }
            }
            out[out.length] = group.join("")
        } else {
            out[out.length] = str
        }
    }
    return out
}

function buildZnaczenia(elem) {
    let withDef = elem.znaczenia.filter(znaczenie => znaczenie.definicja && znaczenie.definicja !== "")
    withDef.sort(compareZnaczenia)
    let list = []
    withDef.forEach(znaczenie => {
        if (znaczenie.lpp) {
            let subs = list[znaczenie.lp].subs
            subs[subs.length] = znaczenie
        } else {
            list[znaczenie.lp] = { main: znaczenie, subs: [] }
        }
    })
    return list
}

function compareZnaczenia(a, b) {
    if (a.lp < b.lp) return -1
    if (a.lp > b.lp) return 1

    if (!a.lpp && b.lpp) return -1
    if (a.lpp && !b.lpp) return 1
    if (!a.lpp && !b.lpp) return 0

    if (a.lpp < b.lpp) return -1
    return 1
}

function SXVIIZnaczeniePair(props) {
    const wide = props.wide
    return <>
        <SXVIIElemZnaczenie znaczenie={props.pair.main} cytaty={props.cytaty} wide={wide} />
        {
            (props.pair.subs.length > 0) ? <ul>
                {
                    props.pair.subs.map(sub =>
                        <li key={sub.id_znaczenia}>
                            <SXVIIElemZnaczenie znaczenie={sub} cytaty={props.cytaty} wide={wide}/>
                        </li>)
                }
            </ul> : <></>
        }
    </>
}

function SXVIIElemZnaczenia(props) {
    if (!props.elem.znaczenia)
        return <SXVIIEmptyZnaczenia {...props}/>
    const znaczenia = buildZnaczenia(props.elem)
    if (znaczenia.length === 0)
        return <SXVIIEmptyZnaczenia {...props}/>
    const cytaty = buildCytatyMap(props.elem.cytaty)
    const wide = props.wide
    return <>
        <div className={wide.get("Data-Line")}>
            <div className={wide.get("Data-Tag")}>{props.translation.get("sXVII.znaczenia")}:</div>
            <ol>
            {
                znaczenia.map(pair => <li key={pair.main.id_znaczenia}>
                    <SXVIIZnaczeniePair pair={pair} cytaty={cytaty} wide={wide} />
                </li>)
            }
            </ol>
        </div>
    </>
}

function SXVIIImPrzymWarn(props) {
    if (!props.result) return <></>
    let shouldWarn = props.result.some(elem => elem.haslo.czm.search("im. przym.") !== -1)
    if (!shouldWarn) return <></>
    let wide = props.wide
    return <div className={wide.get("Data-Div")}>
        <div className={wide.get("Data-SubHeader")}>{props.translation.get("sXVII.im.przym.warn")}</div>
    </div>
}

export { SXVII, sXVIIsource }